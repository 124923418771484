@import "node_modules/bootstrap-less/bootstrap/bootstrap.less";
@import "node_modules/admin-lte/build/less/variables.less";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 300;
}

.error-page {
	background-color: @body-bg;
}